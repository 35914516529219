import { Box, Button, ButtonGroup, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
// @ts-ignore
import TitleBar from "../TitleBar/TitleBar.tsx";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

export default function Contact() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <TitleBar />
            <Container maxWidth="lg">
                <div>
                    <Typography
                        variant="h4">
                        Contact Information
                    </ Typography>
                </div>
                <div>
                    <Button variant="text" href="https://www.instagram.com/MandyJillArt/">
                        <InstagramIcon />
                        @MandyJillArt
                    </Button>
                </div>
                <div>
                    <Button variant="text" href="https://www.facebook.com/MandyJillingsArt">
                        <FacebookIcon />
                        @MandyJillingsArt
                    </Button>
                </div>
                <div>
                    <Button variant="text" href="mailto:info@mandyjillings.art">
                        <EmailIcon />
                        info@mandyjillings.art
                    </Button>
                </div>
            </Container>
        </Box>
    );
  }