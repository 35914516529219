import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
// @ts-ignore
import TitleBar from "../TitleBar/TitleBar.tsx";
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IGalleryEntry from './IGalleryEntry';
import itemData from './gallery.json';

export default function Gallery() {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const gallery = (itemData as IGalleryEntry[]).map((_, id, arr) => arr[arr.length-id-1])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <TitleBar />
            <Container maxWidth="xl">
                <ImageList variant="woven" cols={matchDownMd ? 1 : matchUpLg ? 3 : 2 } gap={4}>
                    {gallery.reverse().map((item,id, arr) => (
                        <ImageListItem key={id}>
                            <img
                                src={`/gallery/medium/${item.jpg}`}
                                srcSet={`/gallery/medium/${item.jpg}`}
                                alt={item.title}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.size}
                                actionIcon={
                                <Link to={`/gallery/${arr.length-id-1}`}>
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.title}`}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Link>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Container>
        </Box>
    );
  }